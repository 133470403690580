<template>
  <div>
    <!--begin::Navbar-->
    <div class="card mb-5 mb-xl-10">
      
    </div>
    <!--end::Navbar-->
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import Globals from "@/views/common/Globals.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DataLayer from "@/core/services/DataLayer";

export default defineComponent({
  // name: "kt-account",
  name: "AccountOverview",
  mixins: [Globals],
  components: {
    // Dropdown3,
  },
  data() {
    return {
      orgName: "",
      orgType: this.getOrgRole(),
      userType: this.getUserRole(),
      organizationId: this.globalData.organizationId,
      emailId: this.globalData.email,
      apiCount: 0,
      userCount: 0,
      manufacturerCount: 0,
      organizationLogo: "",
    };
  },
  methods: {
    // async getProfileData() {
    //   let response = await DataLayer.getOrgProfileData();
    //   this.orgName = response.data.data["name"];
    //   this.organizationLogo = response.data.data["logo"];
    // },
    getApiCount() {
      this.apiCount = 12;
      return this.apiCount;
    },
    getUserCount() {
      this.userCount = 4;
      return this.userCount;
    },
    getManufacturerCount() {
      this.manufacturerCount = 2;
      return this.manufacturerCount;
    },
    // getOrganizationLogo()
    // {
    //   // this.organizationLogo = "media/avatars/150-2.jpg"
    //   this.organizationLogo = ""
    //   return this.organizationLogo
    // },
    usersList() {
      this.$router.push({ name: "usermanagements" });
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("API Key", ["API"]);
    // this.getProfileData();
  },
});
</script>



